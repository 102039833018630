<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">POSTAL SERVICES REQUEST REPORT</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged">
                  </flat-pickr>
              </div>
            </td>


          <td>
            <div class="label_and_element_wrapper">
              <label>
                Carrier
                <span></span>
              </label>
                  <select v-model="post_data.provider_id" @change="fetchPostalDocument">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
              </div>
            </td>


            <td>
                <div class="label_and_element_wrapper">
                  <label>
                  Document Type
                    <span></span>
                  </label>
                    <select v-model="post_data.postal_document_type_id">
                      <option value="all">All</option>
                      <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocuments'+i" :value="postaldocument.id">{{postaldocument.postal_document_type}}</option>
                    </select>
                </div>
             
            </td>

           <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby" @change="resetData">
                  <option value="cost_center">Cost Center</option>
                  <option value="branch_name">Request Branch</option>
                  <option value="carrier_name">Carrier</option>
                  <option value="postal_document_type">Document Type</option>
                </select>
              </div>
            </td>

          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3> Postal Services Request Report</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div
          class="despatch_summary_table_container"
          v-for="(report,key,i) in reports"
          :key="'delivery_report'+i"
        >
          <h2>{{key}}</h2>
          <table>
            <tr>
              <td><b>Date</b></td>
              <td><b>Full Name</b></td>
              <td><b>Request Cost Center</b></td>
              <td><b>Carrier Name</b></td>
              <td><b>Postal Service</b></td>
              <td><b>Requested Date</b></td>
              <td><b>Request Quantity</b></td>
              <td><b>Issue Date</b></td>
              <td><b>Issue Quantity</b></td>
              <td><b>Charge Amount</b></td>
              <td><b>Needed By</b></td>
             
            </tr>
            
            <tr v-for="(courier,i) in report" :key="i">
            
            <td>{{courier.booked_date ? moment(courier.booked_date).format("DD-MM-YYYY"):''}}</td>
            <td>{{courier.requester_name}}</td>
            <td>{{courier.cost_center}}</td>
            <td>{{courier.carrier_name}}</td>
            <td>{{courier.postal_document_type}}</td>
            <td>{{courier.request_date ? moment(courier.request_date).format("DD-MM-YYYY"):''}}</td>
            <td>{{courier.requested_qty}}</td>
             <td>{{courier.issue_by_date ? moment(courier.issue_by_date).format("DD-MM-YYYY"):''}}</td>
            <td>{{courier.issue_qty }}</td>
            <td>{{(courier.total_price/1).toFixed(2)}}</td>
            <td>{{courier.needed_by_date ? moment(courier.needed_by_date).format("DD-MM-YYYY"):''}}</td>
           
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      services: [],
      postaldocuments: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        provider_id:"all",
        postal_document_type_id:"all",
        groupby:"postal_document_type"
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
       save_data:
      {
        actual_courier_amount:"",
        is_actual_courier_amount:"",
        courier_amount_comment:"",

      },
      show_data: false,
    };
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", " Postal Services Request Report"]);
    this.fetchCarrier();
    this.fetchPostalDocument();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
  
    fetchCarrier(){
      this.axios.get("/api/provider/carrierlist/")
      .then(response => {
          this.carriers = response.data.providers;
          this.fetchPostalDocument();
      })
      .catch(error =>{
          this.toast.error();
      });
    },


     // to get postal document type
    fetchPostalDocument(){
      this.axios.get("/api/postaldocument/carrier/"+this.post_data.provider_id)
      .then(response => {
          this.postaldocuments = response.data.postal_document_types;
          this.post_data.postal_document_type_id= this.postaldocuments[0].id;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      this.axios
        .post("/api/reports/getpostalservicerequestreport", this.post_data)
        .then(response => {
          console.log(response);
          this.show_data = true;
   
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },


    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getpostalservicerequestreport/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 25%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>